<template>
  <LayoutManager>
    <div class="mp-main" id="my-programmes-view">
      <div class="mp-title">
        <!-- To be shown only on mobile -->
        <div class="d-block d-sm-none d-md-none d-lg-none">
          {{ $t("general.menu.my_learning") }}
        </div>
        <div class="d-none d-sm-block d-md-block d-lg-block">
          {{ $t("general.menu.my_learning") }}
        </div>
      </div>
      <div class="ld-sec__tabs">
        <my-programmes-tabs></my-programmes-tabs>
      </div>
    </div>
  </LayoutManager>
</template>
<script>
import LayoutManager from "../components/Layout/LayoutManager.vue";
import MyProgrammesTabs from "@/components/MyLearning/MyProgrammesTabs.vue";

export default {
  components: {
    LayoutManager,
    MyProgrammesTabs
  },
  data() {
    return {
      selectedCourseId: "",
      isMobile: false
    };
  },
  computed: {
    activeTab() {
      return this.tabs.find(a => a.active).tabId;
    }
  },
  created() {
    if (this.getLayout !== "main") {
      this.onResize();
      window.addEventListener("resize", this.onResize);
    }
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 768;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  }
};
</script>
<style lang="scss">
@media (max-width: 830px) and (min-width: 768px) {
  .nav-link {
    margin: -8px;
  }
}
.mp-main {
  padding: 20px 52px 10px;

  .mp-title {
    @include subtitle-large;
    line-height: 28px;
    text-align: left;
    margin-bottom: 24px;
    color: $brand-public-secondary;
  }

  .mp-result-found {
    @include label-large;
    text-align: left;
    margin: 20px 0 35px;
    color: $brand-public-disabled;
  }

  .ld-sec__tabs {
    margin-top: 0;
    margin-bottom: 20px;
    position: relative;

    .tabs {
      .nav-tabs {
        width: 100%;
        .nav-item + .nav-item {
          margin-left: 5px;
        }

        .nav-item {
          .nav-link {
            @include button-label;
            padding: 8px 16px;

            &.active {
              color: $brand-primary-400;
              background: $brand-primary-100;
              mix-blend-mode: normal;
              border-radius: 8px;
            }

            &::after {
              content: "";
              border-bottom: 0px solid;
              display: block;
              position: relative;
              top: 0px;
            }
          }
        }
      }
    }
    .ld-sec__grid-layout {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(
        auto-fill,
        minmax(clamp(25% - 24px, 218px, 100%), 1fr)
      );
      gap: 24px;
    }

    .ld-sec__achievements {
      display: flex;
      margin: 0 16px;
    }

    .mp__empty-sec {
      @include flex-horizontal-center;
      height: 400px;

      h5 {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.2px;
        color: $brand-neutral-1000;
      }

      p {
        @include button-label;
        text-align: center;
        letter-spacing: 0.2px;
        color: $brand-disabled-grey;
        margin-bottom: 50px;
      }
    }

    .mp-verify__btn {
      position: absolute;
      top: 0;
      right: 15px;

      &.mp-verify__grey {
        border-color: $brand-disabled-grey;
        color: $brand-disabled-grey;
      }
    }
  }
}

// rtl layout
[dir="rtl"] {
  .mp-main {
    .mp-title {
      text-align: right;
    }

    .ld-sec__tabs {
      .nav-tabs {
        padding-right: 0;
      }
    }
    .mp-result-found {
      text-align: right;
    }
  }
}

@media screen and (max-width: 768px) {
  .mp-main {
    padding: 0px 16px;
    .mp-title {
      position: fixed;
      top: 80px;
      width: 100%;
      line-height: 33px;
      margin: 0;
      letter-spacing: 0.0025em;
      background: $brand-primary-50;
      z-index: 10;
      padding: 16px;
    }

    .tab-content {
      padding-top: 100px;
    }

    .mp-result-found {
      margin: 24px 16px;
    }

    .ld-sec__tabs {
      margin-bottom: 32px;

      .tabs {
        .nav-tabs {
          position: fixed;
          top: 110px;
          z-index: 9;
          background: $brand-primary-50;
          width: 100%;
          margin: 0 0 12px;
          padding: 32px 0 14px;

          .nav-item {
            margin-right: 0;
            margin-bottom: 0;

            .nav-link {
              @include label-large;
            }
          }
        }
      }
    }
  }

  #my-programmes-view {
    .b-sidebar {
      .card {
        width: calc(100% - 32px);
        margin: auto;
        margin-bottom: 16px;
      }

      .course-card + .course-card {
        margin-left: 0px;
      }

      &.b-sidebar-right {
        width: 100%;

        .see-all-sidebar-navbar {
          @include flex-horizontal-center;
          height: 56px;
          position: sticky;
          top: 0;
          z-index: 1;
          background: $brand-neutral-0;
          box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);

          .see-all-sidebar-navbar-back-btn {
            position: absolute;
            left: 0px;
            margin: 8px;
            padding: 8px 12px;
            border-radius: 8px;
            background: $brand-primary-75;
          }

          .see-all-sidebar-navbar-title {
            @include subtitle-small($brand-neutral-200);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) {
  #my-programmes-view {
    .b-sidebar {
      &.b-sidebar-right {
        .b-sidebar-body {
          margin-bottom: 74px;
        }
      }
    }
  }
}
</style>
